const clients = [
  {
    id: 2,
    system: "POC",
    module: "ZNAP_SECURITY",
    modulePath: "security",
    moduleName: "security",
    language: "pt",
    port: 8081,
    theme: "poc.js",
    env: "prod",
  },
];

const getClientConfigs = (clientId) =>
  clients.find((client) => client.id === clientId);
const configs = getClientConfigs(2);

export default {
  ...configs,
};
